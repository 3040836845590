import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Regent({ styles, editing, ...props }) {
  const Elements = useComponents()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="formkit-container">
        <div
          className="formkit-sidebar"
          style={styles.forElement("form_background")}
        >
          <header>
            <Elements.Content
              className="formkit-preheading"
              name="preheader"
              defaults={{
                content: "New material"
              }}
            />
            <Elements.Heading
              className="formkit-heading-primary"
              name="header"
              defaults={{
                content: "Get my free video tutorial."
              }}
            />
          </header>
          <div className="formkit-main">
            <Elements.Region className="formkit-content" name="content">
              <Elements.Content
                defaults={{
                  content:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a aliquet justo. Suspendisse massa."
                }}
              />
            </Elements.Region>
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields>
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Sign up and download now" }}
                />
              </Elements.CustomFields>
            </Elements.Form>
            <Elements.Content
              name="disclaimer"
              defaults={{
                content: "We respect your privacy. Unsubscribe at any time."
              }}
            />
            <Elements.BuiltWith background="form_background" />
          </div>
        </div>
        <div
          style={{
            backgroundColor: styles.forElement("video_background")
              .backgroundColor,
            position: editing ? "absolute" : null
          }}
          className="formkit-video-container"
        >
          <div
            style={styles.forElement("video_background")}
            className="formkit-background-image"
          />
          <Elements.Video name="video" className="formkit-video" />
        </div>
      </div>
    </Elements.LandingPage>
  )
}

Regent.style = "landing_page"
Regent.categories = ["Video"]
Regent.thumbnail = ""
Regent.preview = "https://demo.ck.page/regent"
Regent.fields = [
  {
    name: "first_name",
    label: "Full name"
  },
  {
    name: "email_address",
    label: "E-mail"
  }
]

export default createTemplate(Regent, { name: "Regent" })
